<template>
  <div class="typeList">
    <div class="top">
      <a-button icon="plus-circle" ghost type="primary" @click="handleAddView">添加菜单</a-button>
    </div>
    <div class="fenge" />
    <div class="content">
      <a-spin :spinning="loading">
        <a-table :row-key="(record,index)=>{return record.id}" :data-source="typeList" :pagination="false">
          <a-table-column key="index" title="序号">
            <template slot-scope="text,record,index">
              {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column key="name" title="名称">
            <template slot-scope="text,record">
              {{ record.name }}
            </template>
          </a-table-column>
          <a-table-column key="setting" title="操作" width="200px">
            <template slot-scope="text,record">
              <a-button style="margin-right:10px" ghost type="primary" @click="handleEditView(record)">编辑</a-button>
              <a-button :disabled="record.children.length > 0" :title="record.children.length > 0? '不能删除因为有子菜单': ''" ghost type="danger" @click="handleDeleteView(record)">删除</a-button>
            </template>
          </a-table-column>
        </a-table>
      </a-spin>
    </div>
    <add-type-modal ref="addTypeModal" @on-ok="fetchData" />
    <edit-type-modal ref="editTypeModal" @on-ok="fetchData" />
  </div>
</template>

<script>
import typeApi from '../../api/type'
import AddTypeModal from './component/AddTypeModal.vue'
import EditTypeModal from './component/EditTypeModal.vue'
export default {
  components: { AddTypeModal, EditTypeModal },
  data() {
    return {
      loading: false,
      typeList: [
        {
          id: '',
          name: '',
          children: []
        }
      ],
      menuSimple: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      typeApi.getPointTypeList().then(res => {
        this.makeChildrenFalse(res.data)
        this.typeList = res.data
      }).finally(_ => { this.loading = false })
    },
    makeChildrenFalse(data) {
      data.forEach(item => {
        if (item.children.length === 0) {
          item.children = false
        } else {
          this.makeChildrenFalse(item.children)
        }
      })
    },
    handleAddView() {
      this.$refs.addTypeModal.showAddModal()
    },
    handleEditView(item) {
      this.$refs.editTypeModal.showModal(item)
    },
    handleDeleteView(item) {
      const that = this
      this.$confirm({
        title: `确定删除吗?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          typeApi.delPointTypeById(item.id).then(res => {
            that.$message.success(res.msg)
            that.fetchData()
          })
        },
        onCancel() {
        }
      })
    }
  }
}
</script>
<style scoped>
.typeList {
  padding: 30px;
}
.top{
  display:flex;
}
.fenge {
  height: 1px;
  background-color: #e4e4e4;
  margin-top: 15px;
  margin-bottom: 15px;
}
::v-deep .ant-table-thead > tr > th {
  background-color: #e8f4ff !important;
  color: #1890ff;
}
.content {
  height: calc(100% - 70px);
  overflow-y:auto
}
.content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  background   : #40a9ff;
}
.content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
}
</style>
